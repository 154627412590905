<template>
  <div class="flex-col page">
    <div class="flex-col section_1">
      <span class="text_1">{{title}}</span>
    </div>
    <div class="flex-col section_3">
      <div class="flex-row search">
        <img src="../../../assets/sou.png" class="image_2" />
        <input type="text" class="text_2" v-model="value" placeholder="请输入电梯代码或小区名称" @input="pop(value)"/>
      </div>
      <div class="flex-col list">
        <div class="list-item justify-between" :key="i" v-for="(item, i) in listBuAwzaVe">
          <div class="left-group flex-row" @click="community(item.id,item.HousesName)">
            <img src="../../../assets/icon16.png" class="image_3" />
            <span class="text_3">{{item.HousesName}}</span>
          </div>
          <div class="right-group flex-col items-center" v-if="item.tips">
            <img src="../../../assets/icon7.png" class="image_4" />
            <span class="text_4">{{item.tips}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {list} from '@/api/Property/Maintenance/Maintenance'
export default {
  data() {
    return {
      listBuAwzaVe: [],
      title: '全部区域',
      value: ''
    }
  },
  mounted(){
    list().then(res=>{
      console.log(res.data.Data);
      this.listBuAwzaVe=res.data.Data
    })
  },
  methods:{
    community(params,name){
      sessionStorage.setItem('HousesName',name)
      this.$router.push("/renin/"+params)
    },
    pop(value){
      if(this.value.length===0){
        list().then(res=>{
          this.listBuAwzaVe=res.data.Data
        })
      }
      if(this.value.length>0){
        var arr = [];
        list().then(res=>{
          var len=res.data.Data.length
          for(var j=0;j<len;j++){
          //如果字符串中不包含目标字符会返回-1
            if(res.data.Data[j].HousesName.indexOf(value)!==-1){
              arr.push(res.data.Data[j]);
            }
          }
          this.listBuAwzaVe=arr
        })
      }
    }
  }
}
</script>

<style scoped>
@import '../../../assets/common.css';
.list-item {
  padding: 1rem 0 0.88rem;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
}
.left-group {
  margin: 0.13rem 0 0.25rem;
  color: rgb(178, 178, 178);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.right-group {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.56rem;
  white-space: nowrap;
  width: 1.5rem;
  position: relative;
}
.page {
  padding-bottom: 14.38rem;
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0 0 5.13rem;
  background: url('../../../assets/imgbg1_03.png') center  no-repeat;
  background-size: cover;
}
.section_3 {
  margin-top: -2.56rem;
  padding: 1.81rem 0.94rem 2.5rem;
  background: #fff;
  border-radius: 1rem 1rem 0 0;
  position: relative;
}
.section_2 {
  padding: 0.88rem 0.81rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.text_1 {
  margin-left: 1.5rem;
  margin-top: 1.88rem;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
}
.search {
  padding: 0.63rem 1rem;
  color: rgb(178, 178, 178);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  background-image: url('../../../assets/icon17.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.list {
  padding: 0.63rem 0.063rem 0 0.31rem;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.image_2 {
  width: 0.88rem;
  height: 0.88rem;
  margin-top: 0.3rem;
}
.text_2 {
  margin-left: 0.81rem;
  flex: 1 1 auto;
  border: none;
  outline: none;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.image_3 {
  width: 0.88rem;
  height: 0.81rem;
}
.text_3 {
  margin-left: 0.56rem;
}
.image_4 {
  width: 1.25rem;
  height: 1.25rem;
}
.text_4 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
